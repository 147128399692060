import Box from "./Box"


const Frame = ({ children, borderColor }) => {
    return (
        <Box color={borderColor} shadow={true} thickness="13px">
            <Box thickness="1px" color="rgba(0, 0, 0, .3)">
                <Box color="rgba(0, 0, 0, .2)" thickness="1px">
                    <Box color="rgba(0, 0, 0, .1)" thickness="1px">
                        <Box color="#ffff" thickness="40px">
                            {children}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default Frame