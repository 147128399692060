import { Route,BrowserRouter as Router, Routes, useLocation} from 'react-router-dom';
import "./App.css"

import Form from './components/Form/Form';
import AcceptGift from './components/AcceptGift';
import Submitted from './components/Submitted';
import QueuedNFTs from './components/QueudNFTs';

import Header from './components/Header';
import { useEffect, useState } from 'react';

import { GA4React } from "ga-4-react";
import { useMediaQuery } from 'react-responsive';
import ReactGA from 'react-ga';
import Login from './components/Login';
import AdminChoice from './components/AdminChoice';
import UpdateWallet from './components/UpdateWallet';

ReactGA.initialize('UA-252268748-1')


const Test = () => {

    const isTabletOrMobileDevice = useMediaQuery({
      query: "(max-device-width: 1224px)"
    })

    const style = {
      width: isTabletOrMobileDevice ? "20vh" : "70vh"
    }

    const location = useLocation();

    useEffect(() => {
      ReactGA.pageview(window.location.pathname + window.location.search);
    }, [location])

    return (
    <>
        <Header/>
        <div className='App'>
          <Routes>
            <Route exact path='/Knipe' element={<Form company="Knipe Realty"/>}/>
            <Route exact path='/rsvpre' element={<Form company="RSVP"/>}/>
            <Route exact path='/Vision' element={<Form company="Vision Mortgage"/>}/>
            <Route exact path='/goosehead' element={<Form company="goosehead"/>}/>
            <Route exact path='/' element={<Form company="Padcraft"/>}/>
            <Route exact path='/submitted' element={<Submitted/>}/>
            <Route exact path='/Login' element={<Login/>}/>
            <Route exact path='/updateWallet' element={<UpdateWallet/>}/>
            <Route exact path='/adminpath' element={<AdminChoice/>}/>
            <Route exact path='/queuednfts' element={<QueuedNFTs/>}/>
          </Routes>
        </div>
    </>
    )
}

export default Test