import frame from '../frame_landscape.png'
import framePortrait from '../frame_portrait.png'
import frameWhite from '../frame_white_landscape.png'
import frameWhitePortrait from '../frame_white_portrait.png'
import { browserName, browserVersion, isMobileSafari, isTablet } from "react-device-detect";
import ReactGa from 'react-ga'
import Frame from '../Frame'
import "@expo/match-media";
import { useMediaQuery } from "react-responsive";

import './Third.css'
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const Third = ({ preview, lines, formData, companyStyle, setFormData, setPage }) => {
  const location = useLocation()

  const onOrientationChange = (e) => {
    setFormData({
        ...formData,
        orientation: e.target.value
    })
}

const onFrameChange = (e) => {
    setFormData({
        ...formData,
        frameColor: e.target.value
    })
}

  const isTablet = useMediaQuery({
    query: "(max-device-width: 1224px)",
  });

  const isTabletOrMobileDevice = false

  const textPos = {
    zIndex: "3",
    position: "absolute",
    top: isTablet ? "120px" : "200px",
    fontSize: isTablet ? "10px" : "",
    left: "50%",
    transform: "translate(-50%, -15%)"
  }

  const textPosP = {
    zIndex: "3",
    position: "absolute",
    top: isTablet ? "200px" : "260px",
    fontSize: isTablet ? "10px" : "14px",
    left: "50%",
    transform: "translate(-50%, -15%)"
  }

  const style = {
    landscape1: {
      height: isTabletOrMobileDevice ? "101" : "202.5",
      width: isTabletOrMobileDevice ? "189.5" : "379",
    },
    landscape2: {
      transform: isTabletOrMobileDevice ? "translate(200px)" : "translate(-190px)",
      height: isTabletOrMobileDevice ? "150" : "300px",
      width: isTabletOrMobileDevice ? "250" : "500px",
      marginTop : isTabletOrMobileDevice ? "0" : "-252px"
    },
    portrait2: {
    },
    ltext1: {
      top: isTablet ? "184px" : "308px",
      fontSize: isTablet ? "10px" : "20px",
      fontFamily: formData.fontFamilyLine1
    },
    ltext2: {
      top: isTablet ? "196px" : "330px",
      fontSize: isTablet ? "6px" : "12px",
      fontFamily: formData.fontFamilyLine2
    },
    ptext1: {
      top: isTablet ? "300px" : "457px"
    },
    ptext2: {
      
    }

  }

    return (
      <>
        <div className="mainRunner">
          <h5 style={{paddingBottom: "30px", color: companyStyle.color}}>Step 4 | Approve Design</h5>
          <Frame borderColor={formData.frameColor}>
            {preview === "" ? <p height="100px" width="200px">Please Upload Image to see preview</p>: <img src={preview} width="250"/>}
            <p style={{fontFamily: formData.fontFamilyLine1, marginTop: lines.line1 === "" ? "17%" : "1%"}}>{lines.line1}</p>
            <p style={{fontFamily: formData.fontFamilyLine2, marginBottom: lines.line2 === "" ? "-16.5%" : "-50%", marginTop: "-7%", fontSize: "10px"}}>{lines.line2}</p>
          </Frame>
        </div>
        <div className='step step3'>
                <h5 style={{color: companyStyle.color}}>Step 5 | Choose a Style</h5>
                <div className='orientation'>
                    {/* <div className='style row step3'>
                        <h6>ORIENTATION:</h6>
                        <label className='radio-inline step3'>
                            <input
                                type="radio"
                                name="orientation"
                                id='landscape'
                                value="Landscape"
                                checked={formData.orientation === "Landscape"}
                                onChange={onOrientationChange}
                            />Landscape
                        </label>
                        <label className='radio-inline step3'>
                            <input
                                type="radio"
                                name="orientation"
                                id='portrait'
                                value="Portrait"
                                checked={formData.orientation === "Portrait"}
                                onChange={onOrientationChange}
                            />Portrait
                        </label>
                    </div> */}
                    <div className='style row step3'>
                        <h6>FRAME COLOR:</h6>
                        <label className='radio-inline'>
                            <input
                                type="radio"
                                name="frame_color"
                                id='black'
                                value="Black"
                                checked={formData.frameColor === "Black"}
                                onChange={onFrameChange}
                            />Black
                        </label>
                        <label className='radio-inline step3'>
                            <input
                                type="radio"
                                name="frame_color"
                                id='white'
                                value="White"
                                checked={formData.frameColor === "White"}
                                onChange={onFrameChange}
                            />White
                        </label>
                    </div>
                </div>
            </div>
      </>
    )
}

export default Third