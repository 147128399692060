import { useNavigate } from "react-router-dom"



const AdminChoice = () => {
    const navigate = useNavigate()
    return (
        <div>
            <button onClick={() => {navigate('/updatewallet')}}>Update NFT</button>
            <button onClick={() => {navigate('/queuednfts')}}>Queued NFTs</button>
        </div>
    )
}

export default AdminChoice