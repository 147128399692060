import './Second.css'
import FontPicker from "font-picker-react";
import ReactGa from 'react-ga'
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Second = ({ lines, setLines, setFormData, formData, companyStyle, setPage }) => {

    const style = {
        zIndex: 5
    }
    return (
        <div className="second_wrapper">
            <h5 style={{color: companyStyle.color}}>Step 3 | Add Text</h5>
            <h6 style={{fontSize: "18px"}}>Line 1</h6>
            <div>
                <input style={{width: "300px"}} className="apply-font-line1" required maxLength={40} placeholder="e.g. 101 Columbus Drive" onChange={(e) => {
                    setLines({
                        ...lines,
                        line1: e.target.value,
                    })
                }}
                value={lines.line1}
                />
                <h6 style={{fontSize: "14px", paddingTop: "10px"}}>Choose Your Font</h6>
                <div className='fontPicker'>
                    <FontPicker
                        // style={style}
                        apiKey="AIzaSyAzXEKN93hLiYwxNrcXKRKiZiXL5a674jI"
                        activeFontFamily={formData.fontFamilyLine1}
                        onChange={(nextFont) => {
                            setFormData({
                                ...formData,
                                fontFamilyLine1: nextFont.family
                            })
                        }}
                        pickerId="line1"
                    />
                </div>
            </div>
            <h6 style={{paddingTop: "24px", fontSize: "18px"}}>Line 2</h6>
            <div>
                <input style={{width: "300px"}} className="apply-font-line2" maxLength={40} placeholder="e.g. The Shubert Family" onChange={(e) => {
                    setLines({
                        ...lines,
                        line2: e.target.value
                    })
                }}
                value={lines.line2}
                />
                <h6 style={{fontSize: "14px", paddingTop: "10px"}}>Choose Your Font</h6>
                <FontPicker
                    style={style}
                    apiKey="AIzaSyAzXEKN93hLiYwxNrcXKRKiZiXL5a674jI"
                    activeFontFamily={formData.fontFamilyLine2}
                    onChange={(nextFont) => {
                        setFormData({
                            ...formData,
                            fontFamilyLine2: nextFont.family
                        })
                    }}
                    pickerId="line2"
                />
            </div>
        </div>
    )
}

export default Second