import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

import "./Login.css";

const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
  
    const connect = async (e) => {
      e.preventDefault();
      try {
  
        // send to backend
        const { data } = await axios.post("https://nft-validation.uc.r.appspot.com/api/auth/login", {username, password})
        console.log(data)
        navigate('/adminpath')
      } catch (error) {
        console.log('here')
        console.log(error)
        if (error.message) {
          setError(error.message);
        } else {
          setError(error.response.data.error);
        }
        
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
    return (
        <div className="register-screen">
        <form onSubmit={connect} className="register-screen__form">
          <h3 className="register-screen__title">Login</h3>
          {error && <span className="error-message">{error}</span>}
          <div className="form-group">
            <label htmlFor="email">Username:</label>
            <input
              type="username"
              required
              id="username"
              placeholder="Username"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              tabIndex={1}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Name">Password:</label>
            <input
              type="password"
              required
              id="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              tabIndex={2}
            />
          </div>
          <button type="submit" className="btn btn-dark">
            Login
          </button>
        </form>
      </div>
    )
}

export default Login