import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import padLogo from '../padcraft_logo.png'
import visionLogo from '../vision.png'
import knipeLogo from '../knipe.png'
import rsvpLogo from '../rsvp-new.png'
import gooseLogo from '../goosehead.png'
import visionIco from '../vision.ico'
import rsvpIco from '../rsvp.ico'
import knipeIco from '../knipe.ico'
import gooseIco from '../goosehead.ico'

import "./header.css"

const Header = () => {
    const [logo, setLogo] = useState(padLogo)
    const location = useLocation()
    
    useEffect(() => {

        const queryString = window.location.search
        const urlparams = new URLSearchParams(queryString)
        const companyQuery = urlparams.get("company")

        let link = document.querySelector('link')
        link.rel = 'icon'
        document.getElementsByTagName('head')[0].appendChild(link)

        if (location.pathname.endsWith("knipe") || companyQuery === "Knipe Realty") {
            setLogo(knipeLogo)
            link.href = knipeIco
        } else if (location.pathname.endsWith("vision") || companyQuery === "Vision Mortgage") {
            setLogo(visionLogo)
            link.href = visionIco
        } else if (location.pathname.endsWith("rsvpre") || companyQuery === "RSVP") {
            setLogo(rsvpLogo)
            link.href = rsvpIco
        } else if (location.pathname.endsWith("goosehead") || companyQuery === "goosehead") {
            setLogo(gooseLogo)
            link.href = gooseIco
        }

    }, [])
    

    return (
        <>
            <div className="header"/>
                <div className="logoWrapper">
                    <img className="logo" src={logo} alt="Logo" />
            </div>
        </>
    )
}

export default Header