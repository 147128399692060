import { useEffect } from 'react'
import { useLocation } from 'react-router-dom';
import './First.css'

const First = ({ formData, setFormData, houseImg, setHouseImg, companyStyle, company, setPage }) => {

    useEffect(() => {
        setPage(0)   
    })

    return (
        <div className="first_wrapper">
            <div className='step1'>
            <h2 style={{color: companyStyle.color}}>{company == "Padcraft" ? "Create a Digital Twin of Your Home" : "A Gift from " + company}</h2>
            <body className='msg'>
                {company == "Padcraft" ? "" : "To thank you for business " + company  + " has purchased a 'digital twin' of your home. "}
                Just upload a picture, choose the orientation, frame, and text. We'll record it on the blockchain and send you the NFT of your home.
            </body>
                <h5 style={{color: companyStyle.color, marginTop: "80px"}}>Step 1 | Enter Your Information</h5>
                <div className="step1_inputs">
                    <input style={{marginRight: "5px", width: "200px"}} required autocomplete='off' placeholder="* First Name" onChange={(e) => {
                        setFormData({
                            ...formData,
                            fname: e.target.value,
                        })
                    }}
                    value={formData.fname}
                    />
                    <input style={{width: "200px"}} required placeholder="* Last Name" onChange={(e) => {
                        setFormData({
                            ...formData,
                            lname: e.target.value,
                        })
                    }}
                    value={formData.lname}
                    />
                </div>
                <div>
                <input required placeholder="* Email Address" style={{width: "200px", marginTop: "5px"}} onChange={(e) => {
                        setFormData({
                            ...formData,
                            email: e.target.value
                        })
                    }}
                    value={formData.email}
                    />
                </div>
                <div>
                    <input style={{width: "200px", marginTop: "5px"}} placeholder="Referred By" onChange={(e) => {
                        setFormData({
                            ...formData,
                            agent: e.target.value
                        })
                    }}
                    value={formData.agent}
                    />
                </div>
            </div>
            <div className='step step2'>
                <h5 style={{color: companyStyle.color}}>Step 2 | Upload a Photo of Your Home</h5>
                <div className='img_upload' style={{marginTop: "20px"}}>
                    <label htmlFor="file-upload" className="custom-file-upload">
                        <i className="fa fa-cloud-upload"></i>Upload
                    </label>
                    <input type="file" id='file-upload' onChange={(e) => {
                        setHouseImg({
                            preview: URL.createObjectURL(e.target.files[0]),
                            raw: e.target.files[0]
                        })
                        console.log(houseImg)
                      }}
                      accept="image/*"
                    />
                    { houseImg.preview ? (
                        <img className='uploadedPhoto' src={houseImg.preview} alt="house" width="100" />
                    ) : (
                    <>
                    </>
                    )
                }
                </div>
            </div>
        </div>
    )
}

export default First