import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from "react";

import './UpdateWallet.css'

axios.defaults.withCredentials = true

const UpdateWallet = () => {

    const [email, setEmail] = useState("");
    const [wallet, setWallet] = useState("");
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(false)
  
    const connect = async (e) => {
      e.preventDefault();
      try {
  
        // send to backend
        const { data } = await axios.post("https://nft-validation.uc.r.appspot.com/api/updatewallet", {email, wallet})
        console.log(data)
        if (data.success) {
            setSuccess(true)
            setTimeout(() => {
              setSuccess(false)
            }, 3000)
          }
      } catch (error) {
        console.log('here')
        console.log(error)
        if (error.message) {
          setError(error.message);
        } else {
          setError(error.response.data.error);
        }
        
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    }
    return (
        <div className="register-screen">
        <form onSubmit={connect} className="register-screen__form">
          <h3 className="register-screen__title">Update all NFT orders for given email and wallet</h3>
          {error && <span className="error-message">{error}</span>}
          <div className="form-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              required
              id="email"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              tabIndex={1}
            />
          </div>
          <div className="form-group">
            <label htmlFor="Name">Wallet:</label>
            <input
              type="wallet"
              required
              id="wallet"
              placeholder="wallet"
              onChange={(e) => setWallet(e.target.value)}
              value={wallet}
              tabIndex={2}
            />
          </div>
          <button type="submit" className="btn btn-dark">
            Submit
          </button>
          {success && <span className="success-message">Success... Wallet Uploaded</span>}
        </form>
      </div>
    )
}

export default UpdateWallet