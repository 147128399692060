// Styling
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import knipeIco from './knipe.ico'

// React imports
import { BrowserRouter as Router, Routes, useLocation} from 'react-router-dom';

// Images

// Components
import Test from './Test';
import { useEffect } from 'react';




// import ReactGa from 'react-ga'

// const TRACKING_ID = "UA-252268748-1"
// ReactGa.initialize(TRACKING_ID)

const App = () => {

  // useEffect(() => {
  //   let link = document.querySelector('link')
  //   link.rel = 'icon'
  //   document.getElementsByTagName('head')[0].appendChild(link)
  //   link.href = knipeIco
  // }, [])

  return (
    <Router>
      <div className="Wrapper">
        <Test/>
      </div>
    </Router>
  );
}

export default App;
