import './Form.css'

import { useState } from "react";
import axios from 'axios';
import storage from '../firebaseConfig.js'
import { ref, uploadBytesResumable, getDownloadURL, getStorage} from "firebase/storage"

import First from './First';
import Second from './Second';
import Third from './Third';
import Fourth from './Fourth';
import Fifth from './Fifth';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import AcceptGift from '../AcceptGift';

axios.defaults.withCredentials = true

const Form = ({ g4, company}) => {
    const location = useLocation()
    const navigate = useNavigate("/submitted")
    const [error, setError] = useState("")
    const [page, setPage] = useState(0)
    const [formData, setFormData] = useState({
        fname: '',
        lname: '',
        email: '',
        wallet: '',
        company: '',
        orientation: 'Landscape',
        frameColor: 'Black',
        fontFamilyLine1: 'Open Sans',
        fontFamilyLine2: 'Open Sans',
        agent: ""
    })

    const [houseImg, setHouseImg] = useState({
        preview: '',
        raw: '',
        url: ''
    })
    const [lines, setLines] = useState({
        line1: "",
        line2: ""
    })

    const style = {

    }

    if (company === "Knipe Realty") {
        style.color = "#bcad64"
        style.hover = "#A09354"
    } else if (company === "RSVP") {
        style.color = "#0b3279"
    } else if (company === "Vision Mortgage") {
        style.color = "#1C2325"
    } else if (company === "goosehead") {
        style.color = '#53A531'
    }

    const nextPage = (e) => {
        e.preventDefault()
        let valid = false

        if (formData.fname !== '' && formData.lname !== '' && formData.email !== '' && houseImg.raw !== '') {
            valid = true
        }

        if (valid) {
            setPage(1)
        } else {
            setError("Please Fill in All Required Fields")
            setTimeout(() => {
                setError("")
            }, 2500)
        }
    }


    const submit = async (e) => {
        e.preventDefault()

        if (formData.wallet.length == 42 || formData.wallet.length == 0) {
            const buttons = document.querySelectorAll('Button');
            buttons.forEach((button) => {
                button.disabled = true
            })
            const queryString = window.location.search
            const urlparams = new URLSearchParams(queryString)
            const path = 'images/' + formData.email + "_" + houseImg.raw.name
            const storageRef = ref(storage, path)
            const uploadTask = uploadBytesResumable(storageRef, houseImg.raw)
    
            uploadTask.on(
                (snapshot) => {
                    const percent = Math.round(
                    (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
    
                    },
                    (err) => console.log(err),
                    () => {
                        
                    // download url
                    getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                        setHouseImg({
                            ...houseImg,
                            downloadURL: url
                        })
                    });
            });
    
            const nft = {
                fname: formData.fname,
                lname: formData.lname,
                email: formData.email,
                wallet: formData.wallet,
                houseImg: path,
                line1: lines.line1,
                line2: lines.line2,
                orientation: formData.orientation,
                frameColor: formData.frameColor,
                fontFamilyLine1: formData.fontFamilyLine1,
                fontFamilyLine2: formData.fontFamilyLine2,
                company: company,
                agent: formData.agent
            }
    
            console.log(nft)
    
    
    
            try {
                const result = await axios.post("https://nft-validation.uc.r.appspot.com/api/uploaddata", { nft })
                const haveWallet = nft.wallet != ''
                console.log(result)
                if (result.data) {
                    if (result.data.success) {
                        navigate('/submitted?company=' + company + '&haveWallet=' + haveWallet)
                    } else {
                        buttons.forEach((button) => {
                            button.disabled = true
                        })
                        setError("Server Error")
                        setTimeout(() => {
                            setError("")
                        }, 2500)
                    }
                } else {
                    buttons.forEach((button) => {
                        button.disabled = true
                    })
                    setError("Server Error")
                    setTimeout(() => {
                        setError("")
                    }, 2500)
                }
            } catch (err) {
                buttons.forEach((button) => {
                    button.disabled = true
                })
                setError(err.response.data.error)
                console.log(err)
                setTimeout(() => {
                    setError("")
                }, 2500)
            }
        } else {
            setError("Invalid Wallet Address")
            setTimeout(() => {
                setError("")
            }, 2500)
        }
    }

    return (
        <div className="form-wrapper">
            <form className="form-field" onSubmit={submit}>
                {page === 0 ? (
                    <>
                        <First company={company} g4={g4} formData={formData} setFormData={setFormData} houseImg={houseImg} setHouseImg={setHouseImg} companyStyle={style} setPage={setPage}/>
                        <Second lines={lines} setLines={setLines} formData={formData} setFormData={setFormData} companyStyle={style} setPage={setPage}/>
                        <Third setFormData={setFormData} preview={houseImg.preview} lines={lines} formData={formData} companyStyle={style} setPage={setPage}/>
                        <Fourth formData={formData} submit={submit} page={page} setPage={setPage} companyStyle={style} company={company} houseImg={houseImg} error={error} setError={setError}/>
                    </>
                ) : (
                    <>
                        <Fifth g4={g4} formData={formData} setFormData={setFormData} companyStyle={style} company setPage={setPage}/>
                    </>
                )}
                {/* <Fifth g4={g4} formData={formData} setFormData={setFormData} companyStyle={style} company setPage={setPage}/> */}
                {/* <Fourth formData={formData} submit={submit} page={page} setPage={setPage} companyStyle={style} company={company}/> */}
                {/* {conditionalPage()} */}
                {error && <span className="error-message">{error}</span>}
                <div className='traversal_wrapper'>
                    <div className='next'>
                        {
                            page === 0 ? <></> : <button className='traversal btn btn-primary' style={{backgroundColor: style.color, border: "0px"}} onClick={submit}>Submit</button>
                        }
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Form