import { useEffect } from 'react'
import { useMediaQuery } from "react-responsive";
import { useLocation, useNavigate } from 'react-router-dom'
import './Fourth.css'
import GA4React from 'ga-4-react'


const Fourth = ({ submit, setPage, page, companyStyle, formData, company, houseImg, error, setError }) => {

    const haveWallet = (e) => {
        e.preventDefault()
        e.preventDefault()
        let valid = false

        if (formData.fname !== '' && formData.lname !== '' && formData.email !== '' && houseImg.raw !== '') {
            valid = true
        }

        if (valid) {
            setPage(1)
        } else {
            setError("Please Fill in All Required Fields")
            setTimeout(() => {
                setError("")
            }, 2500)
        }
    }

    const isTablet = useMediaQuery({
        query: "(max-device-width: 1224px)",
      });

    const buttonStyle = {
        backgroundColor: companyStyle.color,
        border: "0px", 
        marginTop: "8px",
        marginLeft: isTablet ? "0px" : "80px"
    }

    return (
        <div className='fourth_wrapper'>
            {/* <body>Your gift will be recorded on the blockchain and delivered to your crypto wallet.</body> */}
            <div className='fourth_wrapper' style={{paddingTop: "60px", textAlign: "center"}}>
                <button className='btns btn btn-primary' style={{backgroundColor: companyStyle.color, border: "0px", marginTop: "8px"}} onClick={haveWallet}>I Have a Crypto Wallet</button>
                <button className='right btns btn btn-primary' style={buttonStyle} onClick={submit}>I Don't Have a Crypto Wallet</button>
            </div>
        </div>

    )
}

export default Fourth