import axios from "axios"
import { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";

import { getStorage, ref, getDownloadURL } from "firebase/storage";

axios.defaults.withCredentials = true

const QueuedNFTs = () => {
    const [criticalError, setCriticalError] = useState("");
    const [nfts, setNFTs] = useState()
    const navigate = useNavigate()

    useEffect(() => {
        const fetchPoll = async () => {
          try {
            axios.get("https://nft-validation.uc.r.appspot.com/api/getQueued").then((response) => {
                setNFTs(response.data.data)
            })
    
          } catch (err) {
            setCriticalError("You are not authorized please login");
          }
        };
    
        fetchPoll();
    }, []);

    const setStatusDeny = async (e) => {
        const orderID = nfts.order_id
        axios.post("https://nft-validation.uc.r.appspot.com/api/denied", { orderID }).then(() => {
          window.location.reload(false);
        })
    }

    const setStatusMinted = async (e) => {
        const orderID = nfts.order_id
        axios.post("https://nft-validation.uc.r.appspot.com/api/minted", { orderID }).then(() => {
          window.location.reload(false);
        })
    }
    
    const viewImage = (e) => {
      if (nfts) {
        if (nfts.picture) {
          const storage = getStorage();
          const pathReference = ref(storage, nfts.picture);
  
          getDownloadURL(pathReference)
          .then((url) => {
              // `url` is the download URL for 'images/stars.jpg'
  
              const img = document.getElementById('myimg');
              img.setAttribute('src', url);
          })
          .catch((error) => {
            console.log(error)
            switch (error.code) {
              case 'storage/object-not-found':
                break;
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;
        
              // ...
        
              case 'storage/unknown':
                // Unknown error occurred, inspect the server response
                break;
            }
          });
        }
      }
    }

  return criticalError ? (
    <span className="error-message">{criticalError}</span>
  ) : (
    nfts ? (
        <div>
        {<p>Email: {nfts.email}</p>}
        {<p>Frame Color: {nfts.frame_color}</p>}
        {<p>Line 1: {nfts.line1}</p>}
        {<p>Line 1 Font: {nfts.line1_fontfamily}</p>}
        {<p>Line 2: {nfts.line2}</p>}
        {<p>Line 2 Font: {nfts.line2_fontfamily}</p>}
        {<p>Wallet: {nfts.wallet_address}</p>}
        <img id="myimg" width="200px"/>
        <div>
          <button onClick={viewImage}>View Image</button>
        </div>
        <div style={{marginTop: "50px"}}>
            <button onClick={setStatusDeny} className="btn btn-dark">Deny</button>
            <button onClick={setStatusMinted} style={{marginLeft: "50px"}} className="btn btn-dark">Minted</button>
        </div>
    </div>
    ) : (
        <p>No Queued Nfts or need to login again</p>
    )
  );
};

export default QueuedNFTs