import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import './Fifth.css'

const Fifth = ({ formData, setFormData, setPage, companyStyle}) => {

    return (
        <div className='fifth_wrapper' style={{marginTop: "60px"}}>
            <h5 style={{color: companyStyle.color}}>Step 6 | Enter Wallet Address</h5>
            <input style={{width: "500px"}} placeholder='Wallet Address' onChange={(e) => {
                setFormData({
                    ...formData,
                    wallet: e.target.value
                })
            }}
            value={formData.wallet}
            />
        </div>
    )
}

export default Fifth