import { useEffect } from "react"
import { useLocation } from "react-router-dom"



const Submitted = () => {

    const queryString = window.location.search
    const urlparams = new URLSearchParams(queryString)
    const company = urlparams.get("company")
    const haveWallet = urlparams.get("haveWallet")

    const style = {

    }

    if (company === "Knipe Realty") {
        style.color = "#bcad64"
        style.hover = "#A09354"
    } else if (company === "RSVP") {
        style.color = "#0b3279"
    } else if (company === "Vision Mortgage") {
        style.color = "#1C2325"
    } else if (company === "goosehead") {
        style.color = "#53A531"
    }

    const onClick = () => {
        window.open("https://help.coinbase.com/en/wallet/getting-started/create-a-coinbase-wallet", "_blank")
    }
    if (haveWallet == 'true') {
        return (
            <div>
                <h5 style={{color: style.color}}>Success! Your Order Has Been Received</h5>
                <body>
                    We'll notify you by email as soon as your NFT has been minted.
                </body>
            </div>
        )
    }
    else {
        return (
            <div>
                <h5 style={{color: style.color}}>Success! Your Order Has Been Received</h5>
                <body>
                    We'll notify you by email as soon as your NFT has been minted.
                </body>
                <body style={{paddingTop: "20px"}}>
                Creating a digital wallet with Coinbase is easy. Click “Create Wallet” below to create yours and when your NFT is ready, we’ll deposit it there.
                </body>
                <button style={{backgroundColor: style.color, border: "0px", marginTop: "20px"}} className="btn btn-primary" onClick={() => {onClick()}}>Create A Wallet</button>
            </div>
        )
    }
}

export default Submitted