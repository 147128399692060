import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
 
// Initialize Firebase
const app = initializeApp ({
    apiKey: "AIzaSyDRmtiU4-NCnjQ3Fk4V8svioRp5kVdTeUo",
    authDomain: "nft-validation.firebaseapp.com",
    projectId: "nft-validation",
    storageBucket: "nft-validation.appspot.com",
    messagingSenderId: "1025680339242",
    appId: "1:1025680339242:web:6d0633b99e3d2e287c2e53",
    measurementId: "G-8PDEGD23SM"
});
 
// Firebase storage reference
const storage = getStorage(app);
export default storage;