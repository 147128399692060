const Box = ({ children, color, thickness, shadow }) => {

    const styles = {
        boxOuter: {
            // width: "400px",
            // height: 120,
            display: "inline-block",
            justifyContent: "center",
            alignItems: 'center',
            margin: 0,
            backgroundColor: '#ffffff',
            borderStyle: "solid",
            borderWidth: thickness,
            borderColor: color,
            textAlign: "center",
            boxShadow: shadow ? "0px 0px 20px 5px gray" : ""
        }
    }

    return (
        <div style={styles.boxOuter}>
            {children}
        </div>
    )
}


export default Box